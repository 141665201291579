import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";
import { useStaticQuery, graphql } from "gatsby";

const DuringEvent = ({ data }) => {
  const { title, subTitle, text, image } = data;

  const { asset, altText } = image;
  const orderImage = asset.gatsbyImageData;

  return (
    <Body>
      <MainText textVariant="h2" fontColor={styles.darkBlue} value={title} />

      <MainText
        textVariant="h3"
        fontColor={styles.deepGreen}
        value={subTitle}
        textAlignment={styles.alignStyleLLLL}
      />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        value={text}
        textAlignment={styles.alignStyleLLLL}
      />

      <Box sx={{ textAlign: "center", py: 5 }}>
        <GatsbyImage image={orderImage} alt={altText || ""} />
      </Box>
    </Body>
  );
};

export default DuringEvent;
