import { Box } from "@mui/material";
import React from "react";
import Body from "../../common/body/Body";
import { FanGoTextProcessor } from "../../common/portableText";
import styles from "../../common/styling/styles";
import { SubText } from "../../common/texts";

const CTA = ({ data }) => {
  return (
    <Body paddingY={styles.allZero}>
      <Box>
        <Box sx={{ py: "1px", pb: 3 }}>
          <SubText
            variant="subtitle1"
            value={<FanGoTextProcessor text={data._rawBody} />}
            marginY={styles.allOne}
            fontColor={styles.grey}
            textAlignment={styles.alignStyleLLLL}
          />
        </Box>
      </Box>
    </Body>
  );
};

export default CTA;
