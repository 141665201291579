import { Box, Card, CardContent } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styles from '../../common/styling/styles';
import Steps from './Steps';

const PreOrderTutorial = ({ data }) => {
  const { tutorial, steps } = data;

  const { asset, altText } = tutorial;
  const tutorialImage = asset.gatsbyImageData;
  return (
    <Card>
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: styles.lightGreen,
          py: 2,
          px: 3,
        }}
      >
        <GatsbyImage image={tutorialImage} alt={altText || ''} />
      </Box>

      <CardContent>
        <Box
          sx={{
            display: styles.displayStyleBBGG,
            gridTemplateColumns: '2fr 2fr 2fr',
            px: { xs: 3, md: 0 },
          }}
        >
          {steps.map((step, index) => (
            <Steps key={index} data={step} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PreOrderTutorial;
