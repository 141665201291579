import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { getBackgroundImageGatsbyData } from '../components/componentUtils';
import {
    InlineImage, SimpleHeaderAndText, TrustedBrandIcons
} from '../components/PageComps/CommonComps';
import CTA from '../components/PageComps/CommonComps/CTA';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import BeforeEvent from '../components/PageComps/PreOrderPageComps/BeforeEvent';
import DidYouKnow from '../components/PageComps/PreOrderPageComps/DidYouKnow';
import DuringEvent from '../components/PageComps/PreOrderPageComps/DuringEvent';
import HowItWorks from '../components/PageComps/PreOrderPageComps/HowItWorks';
import { DataContext } from '../providers/DataProvider';

const PreOrderingPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { sanityPreOrderingPage } = data;
  const { allergensDietaries, pageHead } = sanityPreOrderingPage;
  const { title, subTitle } = pageHead;
  const { body1, body2, body3, contactUs } = allergensDietaries;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="28% 0%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />

      <BeforeEvent data={sanityPreOrderingPage.beforeEvent} />

      <SimpleHeaderAndText data={body1} paddingY={styles.allFive} />

      <InlineImage data={body2} paddingY={styles.allFive} />

      <SimpleHeaderAndText data={body3} paddingY={styles.allFive} />

      <CTA data={contactUs} />

      <DuringEvent data={sanityPreOrderingPage.duringEvent} />

      <DidYouKnow data={sanityPreOrderingPage.didYouKnow} />

      <HowItWorks data={sanityPreOrderingPage.howItWorks} />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default PreOrderingPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityPreOrderingPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityPreOrderingPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      allergensDietaries {
        body1 {
          title
          _rawBody
        }
        body2 {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 500)
          }
          altText
        }
        body3 {
          title
          _rawBody
        }
        contactUs {
          title
          _rawBody
        }
      }
      beforeEvent {
        title
        text
        subTitle
        subTitleText
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 500)
          }
          altText
        }
      }
      duringEvent {
        title
        subTitle
        text
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 500)
          }
          altText
        }
      }
      didYouKnow {
        title
        text
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 350)
          }
          altText
        }
      }
      howItWorks {
        title
        steps
        image {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 500)
          }
          altText
        }
        videoID
        tutorial {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
          }
          altText
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;
