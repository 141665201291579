import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";

const DidYouKnow = ({ data }) => {
  const { title, text, image } = data;

  const { asset, altText } = image;
  const didYouKnowImage = asset.gatsbyImageData;

  return (
    <Body backgroundColor={styles.darkBlue}>
      <Box
        sx={{
          display: styles.displayStyleBBGG,
          gridTemplateColumns: { md: "3fr 2fr" },
        }}
      >
        <Box>
          <MainText
            textVariant="h2"
            value={title}
            textAlignment={styles.alignStyleLLLL}
          />

          <Box
            sx={{
              display: styles.displayStyleBBNN,
              textAlign: "center",
              py: 5,
            }}
          >
            <GatsbyImage image={didYouKnowImage} alt={altText || ""} />
          </Box>

          <SubText
            variant="subtitle1"
            value={text}
            textAlignment={styles.alignStyleLLJJ}
          />
        </Box>

        <Box
          sx={{
            display: styles.displayStyleNNBB,
            textAlign: "center",
            py: 2,
          }}
        >
          <GatsbyImage image={didYouKnowImage} alt={altText || ""} />
        </Box>
      </Box>
    </Body>
  );
};

export default DidYouKnow;
