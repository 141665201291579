import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import { MainText, SubText } from "../../common/texts";

const BeforeEvent = ({ data }) => {
  const { title, text, subTitle, subTitleText, image } = data;

  const { asset, altText } = image;
  const orderPageImage = asset.gatsbyImageData;

  return (
    <Body paddingTop={styles.allTen}>
      <MainText textVariant="h2" fontColor={styles.darkBlue} value={title} />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        value={text}
        textAlignment={styles.alignStyleLLLL}
      />

      <MainText
        textVariant="h3"
        fontColor={styles.deepGreen}
        value={subTitle}
        textAlignment={styles.alignStyleLLLL}
      />

      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        value={subTitleText}
        textAlignment={styles.alignStyleLLLL}
      />

      <Box sx={{ textAlign: "center", py: 5 }}>
        <GatsbyImage image={orderPageImage} alt={altText || ""} />
      </Box>
    </Body>
  );
};

export default BeforeEvent;
