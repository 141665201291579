import React from 'react';

import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import PreOrderTutorial from './PreOrderTutorial';
import VideoPlayer from './VideoPlayer';

const HowItWorks = ({ data }) => {
  const { title, videoID } = data;

  return (
    <Body backgroundColor="primary.light">
      <MainText
        textVariant="h2"
        fontColor={styles.darkBlue}
        value={title}
        marginY={styles.allFive}
      />

      <PreOrderTutorial data={data} />

      {videoID && <VideoPlayer videoID={videoID} />}
    </Body>
  );
};

export default HowItWorks;
