import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/system';
import React from 'react';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';

const Steps = ({ data }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        py: 2,
        margin: 'auto',
        justifyContent: styles.alignStyleLLCC,
        alignItems: 'center',
      }}
    >
      <CheckCircleIcon sx={{ color: styles.deepGreen, mr: 2, fontSize: 40 }} />
      <MainText
        textVariant="h4"
        fontColor="primary.main"
        value={data}
        textAlignment={styles.alignStyleLLLL}
        paddingX={styles.allZero}
      />
    </Box>
  );
};

export default Steps;
